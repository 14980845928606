import React, { useEffect } from 'react'
import useRoutes from '../hooks/useRoutes'
import { beaconPositionDesktop, beaconPositionMobile } from './constants'
import ScreenListener from '../components/reusable_components/responsive/ScreenListener'

// We have two Help Scout apps, one for regular users and one for hosts
let helpScoutAppIds = {}

export const setHelpScoutAppIds = newIds => (helpScoutAppIds = newIds)

export const getHelpScoutAppIds = () => helpScoutAppIds

const isBeaconLoaded = () => {
	return !(typeof Beacon === 'undefined' || typeof Beacon('info') === 'undefined')
}

// When interacting with Beacon, send your code to this beaconCaller for safe handling, since the Help Scout script may not be initiated yet
export function beaconCaller(callback) {
	/*if (isBeaconLoaded()) {
		callback()
	} else {
		// The Help Scout script isn't loaded yet, so fetch it first before calling the callback
		window.helpScoutAsyncDownload(callback)
	}*/
}

export function openBeacon(prefillData) {
	/*beaconCaller(() => {
		// Prefill the form before opening it. Make sure to send an empty string if the value doesn't exist since Beacon doesn't like null/undefined values
		if (prefillData) Beacon('prefill', { subject: prefillData.subject || '' })
		Beacon('open')
	})*/
}

export function logOut(openedInPwa) {
	const routes = useRoutes()
	/*const finishLogOut = () => {
		// Log out the user from Beacon and reset its form
		Beacon('logout')
		Beacon('reset')
		window.location = routes.session.destroy.url
	}
	beaconCaller(finishLogOut)*/
	window.location = routes.session.destroy.url + (openedInPwa ? '?pwa=true' : '')
}

export function hideBeacon() {
	// Don't use beaconCaller here, since this is called from course page on load, and we don't want to load the beacon script on page load since it is bad for performance
	/*const beacon = document.getElementById('beacon-container')
	if (beacon) beacon.style.display = 'none'*/
}

export function showBeacon() {
	// Don't use beaconCaller here, since this is called from course page on load, and we don't want to load the beacon script on page load since it is bad for performance
	/*const beacon = document.getElementById('beacon-container')
	if (beacon) beacon.style.display = 'block'*/
}

// Listen to when the Beacon has loaded, and fire the callback
// Use this instead of beaconCaller when you don't want to force-load the Beacon and then fire your callback, and rather wait for it to have loaded normally and then fire the callback, for performance reasons
export function onBeaconLoaded(callback) {
	/*if (isBeaconLoaded()) {
		callback()
	} else {
		document.addEventListener('beaconLoaded', callback)
	}*/
}

const beaconPositions = [],
	beaconWindowOffset = 70

export function moveBeaconVertically(position, screenType) {
	/*const beacon = document.getElementsByClassName('BeaconFabButtonFrame')[0]
	const beaconWindow = document.getElementsByClassName('BeaconContainer')[0]
	const mobileBeaconIsRendered = document.getElementsByClassName('is-mobile')[0]
	if (beacon) {
		if (beaconPositions.length === 0)
			beaconPositions.push(['phone', 'tablet'].includes(screenType) ? beaconPositionMobile : beaconPositionDesktop) // If there are now previous positions, add the default so we know which positon to reset to later on
		if (beaconPositions[beaconPositions.length - 1] !== position) beaconPositions.push(position) // Only add the the position if a mew position is requested. Otherwise a bug will happen, e.g. if another course page is opened in the sidebar when another course page is already opened
		beacon.style.cssText += `bottom:${position}px !important;`
		if (!mobileBeaconIsRendered) {
			// Also adjust the beacon's popup window position/height which opens when the beacon button is pressed
			// Only do this when their desktop version is rendered though, since the mobile version works fine without this adjustment due to a close button also being rendered at the top of the page. Please note that in DevTools this requires the "device toolbar" to emulate mobile and make the close button appear
			beaconWindow.style.cssText += `bottom:${position + beaconWindowOffset}px !important; height:calc(100% - ${
				position + beaconWindowOffset
			}px) !important;`
		}
	}*/
}

export function resetBeaconVertically() {
	/*const beacon = document.getElementsByClassName('BeaconFabButtonFrame')[0]
	const beaconWindow = document.getElementsByClassName('BeaconContainer')[0]
	const mobileBeaconIsRendered = document.getElementsByClassName('is-mobile')[0]
	if (beacon) {
		beaconPositions.pop()
		beacon.style.cssText += `bottom:${beaconPositions[beaconPositions.length - 1]}px !important;`
		if (!mobileBeaconIsRendered) {
			beaconWindow.style.cssText += `bottom:${
				beaconPositions[beaconPositions.length - 1] + beaconWindowOffset
			}px !important; height:calc(100% - ${
				beaconPositions[beaconPositions.length - 1] + beaconWindowOffset
			}px) !important;`
		}
	}*/
}

export const BeaconAdjuster = props => {
	return null
	return (
		<ScreenListener>
			<BeaconAdjusterLogic {...props} />
		</ScreenListener>
	)
}
// Use to adjust the Beacon's position
const BeaconAdjusterLogic = ({
	adjust, // Whether to move it or not. If true, move it upwards, if false, reset it to its previous position
	screenType,
	source, // Use for debugging which beacon adjuster is doing what
	position = ['phone', 'tablet'].includes(screenType) ? beaconPositionMobile : beaconPositionDesktop, // The position to move it to. If not set, choose the default position
}) => {
	useEffect(() => {
		if (adjust) {
			// Move the Beacon up
			onBeaconLoaded(() => {
				moveBeaconVertically(position, screenType)
			})
		} else {
			// Reset the Beacon's position
			resetBeaconVertically()
		}
	}, [position, adjust])
	useEffect(() => {
		// Reset the Beacon's position on unmount
		return () => resetBeaconVertically()
	}, [])
	return null
}
