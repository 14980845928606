import React from 'react'
import SvgShell from './SvgShell'

const Check = props => (
	<SvgShell {...props}>
		<circle cx="44" cy="44" r="44" />
		<path
			style={{ fill: '#fff' }}
			d="M63.06,29.16a2.19,2.19,0,0,1,.18,3.1L39.78,58.66a2.2,2.2,0,0,1-3.29,0L24.76,45.46A2.2,2.2,0,1,1,28,42.54L38.13,53.89,60,29.34A2.19,2.19,0,0,1,63.06,29.16Z"
		/>
	</SvgShell>
)

export default Check
