import React, { useContext, useEffect } from 'react'
import axios from 'axios'
import UserContext from '../../../../contexts/user/UserContext'
// import { beaconCaller, getHelpScoutAppIds } from '../../../../utils/helpScoutHelper'
import { useTranslation } from 'react-i18next-new'
import { userProviderInitialState } from '../../../../contexts/user/UserProvider'
import useRoutes from '../../../../hooks/useRoutes'
import { useHistory } from 'react-router-dom'

import EjblaLogo from '../../svg_icons/EjblaLogo'
import { CenteredSection, SmallPageSectionDivider } from '../../layout/PageComponents'
import LoginContent from './LoginContent'
import SignUpContent from './SignUpContent'
import SignUpWithEmailContent from './SignUpWithEmailContent'
import ConfirmEmailContent from './ConfirmEmailContent'
import ForgotPasswordContent from './ForgotPasswordContent'
import FavouriteContent from './FavouriteContent'
import DynamicModal from '../DynamicModal'
import SignUpUserOrHostContent from './SignUpUserOrHostContent'
import getWhiteLabel from '../../../../hooks/getWhiteLabel'
import { getCloudinaryImageUrl } from '../../../../utils/imageHelper'
import { WhiteLabelIconWrapper } from '../../../layout/header/Header'
import { getAdminDestination } from '../../../../utils/applicationHelper'
import LoadingSpinner from '../../other/LoadingSpinner'
import styled from 'styled-components'
// import EjblaLogoHostAdmin from '../../svg_icons/EjblaLogoHostAdmin'

// An already existing host logged in
export const initiateHostOnboarding = publishedPastCourseDatesCount => {
	if (publishedPastCourseDatesCount) {
		location = useRoutes().admin.host.overview.url
	} else {
		location = useRoutes().admin.host.progress.url
	}
}

const SessionModal = () => {
	const { userState, dispatch } = useContext(UserContext)
	const { t, i18n } = useTranslation()
	const history = useHistory()
	const routes = useRoutes()
	const { displayState, user, initiateOnboarding, forceOpen, callback, from, tier, tools, pwa } = userState
	const adminDestination = getAdminDestination()
	useEffect(() => {
		if (displayState && forceOpen && !user.signedIn && !adminDestination) {
			document.getElementById('HEADER').style.filter = 'blur(3px)'
			document.getElementById('FILL_PAGE_CONTAINER').style.filter = 'blur(3px)'
		}
	}, [forceOpen])
	if (user.signedIn) return null // Don't open it by accident if they are already logged in

	let closeModal = () =>
		forceOpen ||
		dispatch({
			type: 'SESSION_MODAL_DISPLAY_STATE',
			data: userProviderInitialState,
		})
	let onLogin = (user, hostWantsToUpgradeTier, hostAccountWasCreated, initiateOnboarding) => {
		/*const finishLogIn = () => {
			const helpScoutAppIds = getHelpScoutAppIds()
			const currentAppId = Beacon('info').beaconId
			if (user.isUser && currentAppId !== helpScoutAppIds.user) {
				// Current user is a regular user, but the loaded Help Scout App is our host app, so load the user app
				Beacon('destroy')
				Beacon('init', helpScoutAppIds.user)
			} else if ((user.isHost || user.isSuperAdmin) && currentAppId !== helpScoutAppIds.host) {
				// Current user is a host, but the loaded Help Scout App is our regular user app, so load the host app
				Beacon('destroy')
				Beacon('init', helpScoutAppIds.host)
			}
			// 'identify' starts a session in Help Scout for the user, so they don't need to enter their email and name when interacting with it
			Beacon('identify', { name: user.fullName || '', email: user.email || '' })
		}
		// beaconCaller(finishLogIn)
		// finishLogIn()*/
		if (adminDestination) {
			window.location = adminDestination
		} else {
			if (forceOpen) {
				document.getElementById('HEADER').style.filter = 'none'
				document.getElementById('FILL_PAGE_CONTAINER').style.filter = 'none'
			}
			const callbackOptions = {}
			if (user.isHost) {
				// Host logged in
				if (hostWantsToUpgradeTier) {
					// The host logged in and wants to upgrade tier, so reroute them to the upgrade tier section in host admin
					dispatch({ type: 'SET_USER', data: user })
					localStorage.setItem('upgradeTier', tier)
					history.push(routes.admin.host.overview.path + '?tab=1')
				} else if (hostAccountWasCreated) {
					// A host account was created
					initiateHostOnboarding()
				} else {
					// An already existing host logged in normally, so forward them to host admin
					initiateHostOnboarding(user.publishedPastCourseDatesCount)
				}
			} else if (user.isSuperAdmin) {
				// SA logged in
				window.location = routes.superAdmin.dashboard.url
			} else {
				// User logged in
				// When we add back the user onboarding we need to reactive this if-section
				if (false) {
					// if (initiateOnboarding) {
					window.location = routes.user.onboarding.url
				} else {
					dispatch({ type: 'SET_USER', data: user })
				}
			}
			if (callback) {
				callback(user, callbackOptions)
			}
		}
	}

	const authRequest = (response, onSubmitFinished, provider) => {
		const url = `/api/v1/auth/${provider}/callback?initiate_onboarding=${initiateOnboarding && !from}&tier=${tier}`
		axios({
			method: 'post',
			url: url,
			data: response,
		})
			.then(response => {
				const {
					user,
					confirmedEmail,
					emailToBeConfirmed,
					confirmEmailAuthenticityToken,
					initiateOnboarding,
					hostWantsToUpgradeTier,
					hostAccountWasCreated,
				} = response.data
				if (!confirmedEmail) {
					dispatch({
						type: 'SESSION_MODAL_DISPLAY_STATE',
						data: {
							displayState: 'CONFIRM_EMAIL',
							emailToBeConfirmed,
							confirmEmailAuthenticityToken,
							initiateOnboarding,
							hostWantsToUpgradeTier,
							hostAccountWasCreated,
						},
					})
				} else {
					onLogin(user, hostWantsToUpgradeTier, hostAccountWasCreated)
				}
			})
			.catch(error => {
				if (error.response) {
					onSubmitFinished(error.response.data.errorMessage || t('common:errormessages.generic'))
				}
			})
	}
	const socialMediaButtonProps = {
		i18n,
		t,
		authRequest,
	}
	const childProps = {
		dispatch,
		t,
		onLogin,
		socialMediaButtonProps,
		...userState,
	}
	const whiteLabel = getWhiteLabel()
	return (
		<>
			<DynamicModal
				displayModal={!!displayState}
				setDisplayModal={closeModal}
				hideCloseButton={forceOpen}
				zIndex={9000}
			>
				{/*{tools || (
				<>*/}
				<CenteredSection>
					{whiteLabel.active ? (
						<WhiteLabelIconWrapper src={getCloudinaryImageUrl(whiteLabel.icon, 200)} />
					) : (
						<EjblaLogo onClick={closeModal} disableClick={forceOpen} />
					)}
				</CenteredSection>
				<SmallPageSectionDivider />
				{/*	</>
			)}*/}
				{displayState === 'LOG_IN' && <LoginContent {...childProps} />}
				{displayState === 'SIGN_UP_USER_OR_HOST' && <SignUpUserOrHostContent {...childProps} />}
				{displayState === 'SIGN_UP' && <SignUpContent {...childProps} />}
				{displayState === 'SIGN_UP_WITH_EMAIL' && <SignUpWithEmailContent {...childProps} />}
				{displayState === 'CONFIRM_EMAIL' && <ConfirmEmailContent {...childProps} />}
				{displayState === 'FORGOT_PASSWORD' && <ForgotPasswordContent {...childProps} />}
				{displayState === 'FAVOURITE' && <FavouriteContent {...childProps} />}
				{/*{tools && (
				<CenteredSmallPageSection>
					<EjblaLogoHostAdmin />
				</CenteredSmallPageSection>
			)}*/}
			</DynamicModal>
			{!!getAdminDestination() && (
				<LoadingSpinnerWrapper>
					<LoadingSpinner />
				</LoadingSpinnerWrapper>
			)}
		</>
	)
}

// Loading spinner for desktop when admin destination is present, since the OverLay modal can't SSR and can take a second to load, especially on bad network
const LoadingSpinnerWrapper = styled.div`
	position: fixed;
	left: 50%;
	top: 300px;
	transform: translateX(-50%);
	z-index: 1;
	opacity: 0.3;
`

export default SessionModal
