import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from 'urql'

import LoadContainer from '../../../layout/LoadContainer'
import {
	CenteredPageSection,
	RepulsiveRow,
	SmallPageSection,
	SmallPageSectionDivider,
	SmallPageSectionRepulsiveRow,
} from '../../../../reusable_components/layout/PageComponents'
import { Link, useParams } from 'react-router-dom'
import ResponsiveImage from '../../../../reusable_components/responsive/ResponsiveImage'
import { TicketCourseWrapper, TicketImageWrapper } from '../index/TicketsPage'
import { P2 } from '../../../../reusable_components/Typography'
import CrocodileRight from '../../../../reusable_components/svg_icons/CrocodileRight'
import Reschedule from './Reschedule'
import ToggleableElement from '../../../../reusable_components/other/ToggleableElement'
import Cancel from './Cancel'
import styled from 'styled-components'
import { theme } from '../../../../../utils/theme'
import { AdminBreadcrumbs } from '../../../layout/PageComponents'
import { DateAndTimeInfo } from '../../../../reusable_components/other/CourseComponents'
import CrocodileUp from '../../../../reusable_components/svg_icons/CrocodileUp'
import CrocodileDown from '../../../../reusable_components/svg_icons/CrocodileDown'

export const ClickableRow = styled(SmallPageSectionRepulsiveRow)`
	cursor: pointer;
	> svg {
		transition: transform 0.2s;
		transform: rotateZ(${({ toggled }) => (toggled ? 90 : 0)}deg);
	}
	> div:first-child {
		display: flex;
		align-items: center;
		> *:not(:first-child) {
			margin-left: ${theme.margins.XXSmall};
		}
	}
	margin-bottom: 0;
`

const getTicket = gql`
	query ($id: ID!) {
		ticket(id: $id) {
			id
			transferPath
			userId
			booking {
				id
				userId
				refundedAt
				isRefunded
				totalPrice
				canBeCancelled
				canBeRescheduled
				seats
				refundCutOff
				refundCutOffDate
				serviceFee
				course {
					id
					title
					defaultImagePath
					physicalAddress
					purchasePolicyUrl
					upcomingCourseDates {
						id
						allDatesStringShort
						allDatesStringLong
						availableSeats
						payOnSite
						consecutiveCourseDates {
							date
							timeFrame
						}
					}
					host {
						fullName
						hostPage {
							path
						}
					}
				}
				previousCourseDate {
					payOnSite
				}
			}
			courseDate {
				id
				date
				nextDate
				timeFrame
				nextTimeFrame
				hasEnded
				status
				payOnSite
				consecutiveCourseDates {
					date
					timeFrame
					hasEnded
				}
				automaticRefundDate
			}
		}
	}
`
const CancelOrReschedulePage = ({ t, user }) => {
	const [result] = useQuery({
		query: getTicket,
		variables: { id: useParams().id },
	})

	const { data, fetching, error } = result

	if (fetching || error) return <LoadContainer error={error} fetching={fetching} />
	const ticket = data.ticket
	const { courseDate, booking, transferPath } = ticket
	const { course } = booking
	const { title, defaultImagePath, host, upcomingCourseDates, purchasePolicyUrl } = course
	const { hostPage } = host
	const userIsParticipating = user.id === ticket.userId // The user is participating. Can be false if the user is the booker, but is not participating him/herself
	const isBooker = booking.userId === user.id
	const isPublished = courseDate ? courseDate.status === 'published' : false
	const otherUpcomingDates = []
	const previousCourseDate = courseDate || booking.previousCourseDate
	for (let upcomingCourseDate of upcomingCourseDates)
		if (
			(!courseDate || courseDate.id !== upcomingCourseDate.id) &&
			booking.seats <= upcomingCourseDate.availableSeats &&
			((previousCourseDate.payOnSite && upcomingCourseDate.payOnSite) || // Make sure a booking for a pay_on_site date can only reschedule to another pay_on_site date
				(!previousCourseDate.payOnSite && !upcomingCourseDate.payOnSite)) // Make sure a booking for a non pay_on_site date can only reschedule to another non pay_on_site date
		)
			otherUpcomingDates.push(upcomingCourseDate)
	return (
		<>
			<AdminBreadcrumbs>{t('admin:cancelOrReschedulePage.pageTitle')}</AdminBreadcrumbs>
			<TicketCourseWrapper>
				<TicketImageWrapper>
					<ResponsiveImage type="SQUARE" imagePath={defaultImagePath} />
				</TicketImageWrapper>
				<div>
					<P2 useAsTitle>{title}</P2>
					{courseDate && <DateAndTimeInfo courseDate={courseDate} />}
					<p>
						{t('admin:ticketsPage.hostedBy')}{' '}
						{hostPage?.path ? <Link to={hostPage.path}>{host.fullName}</Link> : host.fullName}
					</p>
				</div>
			</TicketCourseWrapper>
			<SmallPageSection>{isBooker || <p>{t('admin:cancelOrReschedulePage.description2')}</p>}</SmallPageSection>
			{isBooker && !booking.isRefunded && (
				<>
					<SmallPageSectionDivider />
					<ToggleableElement
						toggleOnMount={!courseDate || !isPublished}
						toggler={toggled => (
							<RepulsiveRow>
								<p>{t('admin:cancelOrReschedulePage.reschedule')}</p>
								{toggled ? <CrocodileUp /> : <CrocodileDown />}
							</RepulsiveRow>
						)}
					>
						<Reschedule
							ticket={ticket}
							t={t}
							otherUpcomingDates={otherUpcomingDates}
							userIsParticipating={userIsParticipating}
							isPublished={isPublished}
						/>
					</ToggleableElement>
				</>
			)}
			{courseDate && userIsParticipating && isPublished && !booking.isRefunded && courseDate.nextDate && (
				<>
					<SmallPageSectionDivider />
					<Link to={transferPath}>
						<RepulsiveRow>
							<p>{t('admin:cancelOrReschedulePage.transferSpot')}</p>
							<CrocodileRight />
						</RepulsiveRow>
					</Link>
				</>
			)}
			{courseDate && isBooker && isPublished && courseDate.nextDate && (
				<>
					<SmallPageSectionDivider />
					<ToggleableElement
						toggler={toggled => (
							<RepulsiveRow>
								<p>{t('admin:cancelOrReschedulePage.cancel')}</p>
								{toggled ? <CrocodileUp /> : <CrocodileDown />}
							</RepulsiveRow>
						)}
					>
						<Cancel ticket={ticket} t={t} userIsParticipating={userIsParticipating} isPublished={isPublished} />
					</ToggleableElement>
				</>
			)}
			<CenteredPageSection>
				<p>
					<a href={purchasePolicyUrl} target="_blank" rel="noopener">
						{t('common:documents.bookingPolicy')}
					</a>
				</p>
				<br />
			</CenteredPageSection>
		</>
	)
}

export default CancelOrReschedulePage
