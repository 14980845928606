import { ProfilePicture } from './CourseComponents'
import FollowButton from '../buttons/FollowButton'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { theme } from '../../../utils/theme'
import ContentSideBarContext from '../../../contexts/content_side_bar/ContentSideBarContext'
import { TextSpan } from '../Typography'
import Verified from '../svg_icons/Verified'
import ToolTip from './ToolTip'
import { LeftAlignedFlexBox } from '../layout/PageComponents'

export const StyledSmallHostSection = styled.div`
	display: flex;
	align-items: center;
	> div:first-child {
		margin-right: ${theme.margins.small};
	}
`
export const ButtonSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	> * {
		margin-top: ${theme.margins.XSmall};
		:first-child {
			margin-right: ${theme.margins.XXSmall};
		}
	}
`
export const SmallHostSection = ({ host, t, marketplace }) => {
	const {
		id,
		isPublished,
		fullName,
		subHostFullName,
		hostPicture,
		subHostPicture,
		hostSince,
		displayNameExtensionCoursePage,
		isVerified,
	} = host
	const { dispatch } = useContext(ContentSideBarContext)
	const courseHostFullName = subHostFullName || fullName
	const courseHostPicture = subHostPicture || hostPicture
	const displayHostPageLink = isPublished && (host.marketplace || (!host.marketplace && !marketplace)) // Only display link to host page if it is on marketplace, or if both the course and host page isn't on marketplace
	const onClick = event => {
		// The card is an a tag link so it can be indexed by Google, but we want the overlay to open instead of
		// the user being rerouted to the host page, so prevent the default of the a tag.
		event.preventDefault()
		dispatch({ type: 'NEW_CONTENT', data: { content: host, contentType: 'HOST' } })
	}
	return (
		<StyledSmallHostSection>
			<div>
				{displayHostPageLink ? (
					<a href={host.url} onClick={onClick}>
						<ProfilePicture profileName={courseHostFullName} width={150} imagePath={courseHostPicture} />
					</a>
				) : (
					<ProfilePicture profileName={courseHostFullName} width={150} imagePath={courseHostPicture} />
				)}
			</div>
			<div>
				<LeftAlignedFlexBox>
					<p>
						{displayHostPageLink ? (
							<a href={host.url} onClick={onClick}>
								{courseHostFullName}
							</a>
						) : (
							courseHostFullName
						)}
					</p>
					{isVerified && (
						<ToolTip
							name="verifiedTooltip"
							place="top"
							icon={<Verified viewBox="0 0 92.44 87" width={19} height={18} />}
						>
							{t('copy:coursePage.verifiedTooltip')}
						</ToolTip>
					)}
				</LeftAlignedFlexBox>
				<TextSpan textColor="lightTextColor">
					{displayNameExtensionCoursePage || t('copy:coursePage.hostSince', { year: hostSince })}
				</TextSpan>
				<ButtonSection>
					{displayHostPageLink && <FollowButton favouritableType="hostPage" favouritableId={id} />}
				</ButtonSection>
			</div>
		</StyledSmallHostSection>
	)
}
