import React from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import InfoNew from '../svg_icons/InfoNew'
import styled from 'styled-components'
import { theme } from '../../../utils/theme'

const TooltipContainer = styled.div`
	width: fit-content;
	> span {
		display: flex;
		cursor: default;
		background-color: Transparent;
		background-repeat: no-repeat;
		border: none;
		overflow: hidden;
		outline: none;
		padding: 0;
		color: ${theme.colors.orangeLightlyFaded};
	}
	> div {
		max-width: 250px;
		padding: ${theme.paddings.XSmall};
		white-space: pre-line;
		z-index: 1000;
	}
`

const ToolTip = props => {
	// const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
	const place = props.place || 'top'
	const onClick = event => {
		// If the tooltip is inside another clickable element, disable the onClick on that parent element
		// This is because the user only wants to open the tooltip when clicking on it, nothing else
		event.stopPropagation()
		event.preventDefault()
	}
	return (
		<TooltipContainer onClick={onClick}>
			<span data-tooltip-id={props.name}>
				{props.icon || <InfoNew viewBox="0 0 21 21" width={20} height={20} {...props} />}
			</span>
			<ReactTooltip id={props.name} place={place}>
				{props.children}
			</ReactTooltip>
		</TooltipContainer>
	)
}

export default ToolTip
