import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next-new'
import { getOriginalCloudinaryUrl } from '../../utils/imageHelper'
import ContentSideBarContext from '../../contexts/content_side_bar/ContentSideBarContext'
import useLocation from '../../hooks/useLocation'

// Dynamically changes the content of <head> depending on page.
// When a child HTML tag is inserted, it overrides and replaces any HTML tags of that type already present in <head>
// The <link rel="alternate".. /> links are however not overridden by default, so we need to manually remove them from the original page
// if e.g. a content side bar gets opened or if a page (e.g. SubCategoryPage) has it's own localizedUrls
const HelmetHead = ({
	title,
	description,
	image,
	renderedJsonLd,
	topLevelHelmet,
	localizedUrls,
	seoIndexed = true,
	defaultLocalizedUrls = true,
}) => {
	const { state } = useContext(ContentSideBarContext)
	const { t, i18n } = useTranslation()
	const location = useLocation()
	const canonicalUrl = location.origin + location.pathname
	if (defaultLocalizedUrls && !localizedUrls) {
		// Construct default localized urls. This is done when the only difference between the URL:s is the locale, e.g. ejbla.com/sv and ejbla.com/en
		// E.g. SubCategoryPage has other differences in URL however, e.g. /sv/skills/13-matlagning-dryck/21-bakning vs /en/skills/13-cooking-beverages/21-baking, so it sets defaultLocalizedUrls to false and provides its own URL:s
		localizedUrls = {}
		for (const locale of ['sv', 'en'])
			localizedUrls[locale] = canonicalUrl.replace(
				`${location.origin}/${i18n.language}`,
				`${location.origin}/${locale}`,
			)
	}
	// Fallback copy for pages we don't SEO optimize, e.g. purchase page, course date page, admin pages
	if (!title) title = t('helmet:startPage.title')
	if (!description) description = t('helmet:startPage.description')
	let sideBarIsOpen = state.sideBarIsOpen
	let insertLocalizedUrls = true
	// Remove the main page's (topLevelHelmet) URL:s when the side bar is open
	if (topLevelHelmet && sideBarIsOpen) insertLocalizedUrls = false
	return (
		<Helmet>
			<title>{title}</title>
			{seoIndexed ? '' : <meta name="robots" content="noindex" />}
			<meta name="description" content={description} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			{image && <meta property="og:image" content={getOriginalCloudinaryUrl(image)} />}
			{insertLocalizedUrls &&
				localizedUrls &&
				Object.entries(localizedUrls).map(([locale, url]) => (
					<link rel="alternate" href={url} hrefLang={locale === 'sv' ? 'sv-default' : locale} key={locale} />
				))}
			<link rel="canonical" href={canonicalUrl} />
			{/*{renderedJsonLd && (
				<script type="application/ld+json" defer="defer">
					{renderedJsonLd}
				</script>
			)}*/}
		</Helmet>
	)
}

export default HelmetHead
