import React, { useContext, useState } from 'react'
import gql from 'graphql-tag'
import { useMutation, useQuery } from 'urql'
import { useTranslation } from 'react-i18next-new'
import { getData } from 'country-list'

import Form from '../../../reusable_components/forms/Form'
import TextInput from '../../../reusable_components/forms/TextInput'
import Button from '../../../reusable_components/buttons/Button'
import {
	FormSectionRow,
	GrayBox,
	LeftAlignedFlexBox,
	SmallPageSection,
	SmallPageSectionDivider,
	SmallPageSectionRepulsiveRow,
	XSmallPageSection,
} from '../../../reusable_components/layout/PageComponents'
import { AdminBreadcrumbs } from '../../layout/PageComponents'
import SelectInput from '../../../reusable_components/forms/SelectInput'
import LoadContainer from '../../layout/LoadContainer'
import UserContext from '../../../../contexts/user/UserContext'
import { Markdown, P3, UnderlinedSpan } from '../../../reusable_components/Typography'
import ToggleableElement from '../../../reusable_components/other/ToggleableElement'
import CrocodileUp from '../../../reusable_components/svg_icons/CrocodileUp'
import CrocodileDown from '../../../reusable_components/svg_icons/CrocodileDown'
import ToolTip from '../../../reusable_components/other/ToolTip'
import Check from '../../../reusable_components/svg_icons/Check'
import { theme } from '../../../../utils/theme'

// Used to update the user context with tier data when either updating tier or invoice details
// When adding data here, also add that data to get_current_user_data, and to all other UserContextFragments
// Don't export and reuse, because the build on production fails then for some reason.
const UserContextFragment = gql`
	fragment userContext on User {
		tier
		tierDate
		needsStandard
		needsInvoiceDetails
		needsInvoiceDetailsForFeature
	}
`

const getUser = gql`
	query ($id: ID!) {
		user(id: $id) {
			id
			firstName
			lastName
			phone
			invoiceType
			invoiceTypes
			socialSecurityNumber
			birthday
			corporateIdentityNumber
			vatNumber
			company
			address
			zipCode
			city
			country
			taxCountry
			meetsDac7Criteria
			canAcceptPayments
		}
	}
`

const UpdateUser = gql`
	mutation ($input: UpdateUserInput!) {
		updateUser(input: $input) {
			user {
				...userContext
			}
			errors {
				path
				message
			}
		}
	}
	${UserContextFragment}
`

const Dac7Page = props => {
	const { t } = useTranslation()
	const [result] = useQuery({
		query: getUser,
		variables: { id: props.user.id },
		pause: !props.user.id, // Only load form when the user has logged in and has an ID. Otherwise we will see an error e.g. when this form is rendered prior to the host creating an account and receiving an ID on /kom_igang
		requestPolicy: 'cache-and-network', // Reload every time, in case the user has updated their business info in Stripe. In that case we have received those changes in Stripe and need to display them here
	})
	const { data, fetching, error } = result
	if (fetching || error) return <LoadContainer error={error} fetching={fetching} />
	const user = data?.user
	return (
		<>
			{props.skipBreadcrumbs || <AdminBreadcrumbs>{t('admin:dac7Page.pageTitle')}</AdminBreadcrumbs>}
			{user && (
				<Dac7Form
					t={t}
					user={user}
					callback={props.callback}
					openInModal={props.skipBreadcrumbs}
					needsInvoiceDetails={props.user.needsInvoiceDetails}
				/>
			)}
		</>
	)
}

const Dac7Form = ({ t, user, callback, openInModal, needsInvoiceDetails }) => {
	const [updateUserResult, updateUser] = useMutation(UpdateUser)
	const [invoiceType, setInvoiceType] = useState(user.invoiceType)
	const { userState, dispatch } = useContext(UserContext)
	const submit = (input, onSubmitFinished) => {
		const variables = {
			input: { id: user.id, ...input },
		}
		updateUser(variables).then(response => {
			onSubmitFinished(response.error || response?.data?.updateUser, () => {
				// Update the user context with the new needsInvoiceDetails
				const newUser = { ...userState.user, ...response.data.updateUser.user }
				dispatch({ type: 'SET_USER', data: newUser })
				callback && callback()
			})
		})
	}
	const countries = getData()
	return (
		<>
			<SmallPageSection>
				<Markdown linkTarget="_blank">{t('admin:dac7Page.pageDescription')}</Markdown>
				<ToggleableElement
					toggler={toggled => (
						<XSmallPageSection>
							<LeftAlignedFlexBox>
								<UnderlinedSpan>{t('admin:dac7Page.requirementsQuestion')}</UnderlinedSpan>
								{toggled ? <CrocodileUp /> : <CrocodileDown />}
							</LeftAlignedFlexBox>
						</XSmallPageSection>
					)}
					skipPadding
				>
					<div>
						<GrayBox style={{ maxWidth: '500px' }}>
							{openInModal ? (
								t('admin:dac7Page.requirementsAnswerModal')
							) : (
								<>
									<Markdown>{t('admin:dac7Page.requirementsAnswer')}</Markdown>
									<SmallPageSectionRepulsiveRow>
										<LeftAlignedFlexBox>
											<P3>
												<b>{t('admin:dac7Page.paid', { year: new Date().getFullYear() })}:</b>&nbsp;
												{t('common:buttons.' + (user.meetsDac7Criteria ? 'yes' : 'no'))}
											</P3>
										</LeftAlignedFlexBox>
										<div>
											<ToolTip
												name={'PaidCheckmark'}
												icon={
													<Check
														style={{ fill: user.meetsDac7Criteria ? theme.colors.green : theme.colors.darkGray }}
														viewBox="0 0 88 88"
														width={17}
														height={17}
													/>
												}
											>
												{t('admin:dac7Page.' + (user.meetsDac7Criteria ? 'achieved' : 'notAchieved'))}
											</ToolTip>
										</div>
									</SmallPageSectionRepulsiveRow>
									{user.meetsDac7Criteria ? (
										<p>{t('admin:dac7Page.requirementMet')}</p>
									) : (
										<p>{t('admin:dac7Page.requirementNotMet')}</p>
									)}
								</>
							)}
						</GrayBox>
					</div>
				</ToggleableElement>
				{openInModal || (
					<XSmallPageSection>
						<LeftAlignedFlexBox>
							<P3>
								<b>Status:</b>&nbsp;
								{t('admin:dac7Page.form' + (needsInvoiceDetails ? 'NotSent' : 'Sent'))}
							</P3>
							<ToolTip name="STATUS">
								{t(`admin:dac7Page.form${needsInvoiceDetails ? 'NotSent' : 'Sent'}Tooltip`)}
							</ToolTip>
						</LeftAlignedFlexBox>
					</XSmallPageSection>
				)}
			</SmallPageSection>
			{/*<Form onSubmit={submit} showSavedText forceDisable={user.finishedStripeOnboarding}>*/}
			<Form onSubmit={submit} showSavedText>
				<SelectInput
					value={invoiceType}
					name="invoiceType"
					options={user.invoiceTypes}
					onChange={value => setInvoiceType(value)}
				/>
				{invoiceType === 'invoice_as_company' ? (
					<>
						<FormSectionRow>
							<TextInput value={user.company} name="company" tooltip={t('common:forms.tooltips.company')} required />
							<TextInput value={user.corporateIdentityNumber} name="corporateIdentityNumber" required />
						</FormSectionRow>
						<FormSectionRow>
							<SelectInput
								value={user.taxCountry}
								name="taxCountry"
								tooltip={t('common:forms.tooltips.taxCountryCompany')}
								options={countries}
							/>
							<TextInput
								value={user.vatNumber}
								name="vatNumber"
								tooltip={t('common:forms.tooltips.vatNumberCompany')}
								required
							/>
						</FormSectionRow>
						<SmallPageSectionDivider />
						<b>{t('admin:dac7Page.registeredAddressCompany')}</b>
					</>
				) : (
					<>
						<FormSectionRow>
							<TextInput value={user.firstName} name="firstName" required />
							<TextInput value={user.lastName} name="lastName" required />
						</FormSectionRow>
						<FormSectionRow>
							{/* Birthday is needed since international people w/o Swedish personnummer doesn't always have their birthday in them */}
							{/* Using type="date" instead of pickadate since with this one they can much easily change year. Since all browsers doesn't support it however, we save it as a string instead of date time since we don't enforce a format if they enter it by typing on a browser w/o support */}
							<TextInput value={user.birthday} name="birthday" type="date" required />
							<TextInput value={user.socialSecurityNumber} name="socialSecurityNumber" required />
						</FormSectionRow>
						<FormSectionRow>
							<SelectInput
								value={user.taxCountry}
								name="taxCountry"
								tooltip={t('common:forms.tooltips.taxCountryPerson')}
								options={countries}
							/>
							<TextInput
								value={user.vatNumber}
								name="vatNumber"
								tooltip={t('common:forms.tooltips.vatNumberPerson')}
								required
							/>
						</FormSectionRow>
						<SmallPageSectionDivider />
						<b>{t('admin:dac7Page.registeredAddressPerson')}</b>
					</>
				)}
				<FormSectionRow>
					<TextInput value={user.address} name="address" required />
					<TextInput value={user.zipCode} name="zipCode" required />
				</FormSectionRow>
				<FormSectionRow>
					<TextInput value={user.city} name="city" required />
					<SelectInput value={user.country} name="country" options={countries} />
				</FormSectionRow>
				<TextInput value={user.phone} name="phone" required />
				{user.canAcceptPayments && (
					<>
						<SmallPageSectionDivider />
						<b>{t('admin:dac7Page.bankDetails')}</b>
						<p>{t('admin:dac7Page.bankDetailsDescription')}</p>
						<FormSectionRow>
							<TextInput value={user.bank} name="bank" required />
							<TextInput value={user.accountNumber} name="accountNumber" required />
						</FormSectionRow>
					</>
				)}
				<Button wide buttonType="RECTANGLE" savedText={t('common:buttons.saved!')}>
					{t('common:forms:save')}
				</Button>
			</Form>
		</>
	)
}

export default Dac7Page
