import { isIosDevice } from './applicationHelper'

export function disableBodyScroll(modal) {
	let initialClientY = null // remember Y position on touch start

	if (isIosDevice() && modal) {
		modal.ontouchstart = registerScrollStartPosition
		modal.ontouchmove = disableRubberBand
	}

	function registerScrollStartPosition(event) {
		if (event.targetTouches.length === 1) {
			// detect single touch
			initialClientY = event.targetTouches[0].clientY // Position of where the finger lands
		}
	}

	function disableRubberBand(event) {
		if (event.targetTouches.length === 1) {
			const scrollDirection = event.targetTouches[0].clientY - initialClientY < 0 ? 'DOWN' : 'UP'

			if (modal.scrollTop === 0 && scrollDirection === 'UP') {
				// Modal is at the top of its scroll
				event.preventDefault()
			}

			if (isModalTotallyScrolled(modal) && scrollDirection === 'DOWN') {
				// Modal is at the bottom of its scroll
				event.preventDefault()
			}
		}
	}

	function isModalTotallyScrolled(modal) {
		// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
		return modal.scrollHeight - modal.scrollTop <= modal.clientHeight
	}

	// Setting overflow on body/documentElement synchronously in Desktop Safari slows down
	// the responsiveness for some reason. Setting within a setTimeout fixes this.
	setTimeout(() => {
		// Add padding to body to compensate for the scroll bar going missing when setting its overflow to hidden
		const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth
		if (scrollBarWidth > 0) {
			document.body.style.paddingRight = `${scrollBarWidth}px`
		}
		document.getElementsByTagName('html')[0].style.overflow = 'hidden'
	})
}

export function enableBodyScroll(modal) {
	if (isIosDevice() && modal) {
		modal.ontouchstart = null
		modal.ontouchmove = null
	}

	// Setting overflow on body/documentElement synchronously in Desktop Safari slows down
	// the responsiveness for some reason. Setting within a setTimeout fixes this.
	setTimeout(() => {
		document.body.style.paddingRight = '0'
		document.getElementsByTagName('html')[0].style.overflow = null
	})
}
