import React from 'react'
import DynamicModal from './DynamicModal'
import Dac7Page from '../../admin/host/user/Dac7Page'

const InvoiceDetailsModal = props => {
	const { modalIsOpen, setDisplayModal, user, callback, hideCloseButton } = props
	return (
		<DynamicModal
			setDisplayModal={hideCloseButton ? () => {} : setDisplayModal}
			displayModal={modalIsOpen}
			hideCloseButton={hideCloseButton}
		>
			<Dac7Page user={user} callback={callback} skipBreadcrumbs />
		</DynamicModal>
	)
}

export default InvoiceDetailsModal
