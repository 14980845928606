import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from 'urql'
import useRoutes from '../../../../../hooks/useRoutes'

import { Trans } from 'react-i18next-new'
import Form from '../../../../reusable_components/forms/Form'
import Button from '../../../../reusable_components/buttons/Button'
import { Link, useHistory } from 'react-router-dom'
import {
	CenteredSection,
	SmallPageSection,
	SmallPageSectionDivider,
} from '../../../../reusable_components/layout/PageComponents'
import DynamicModal from '../../../../reusable_components/modals/DynamicModal'
import { P2, P3, P4 } from '../../../../reusable_components/Typography'
import TextAreaInput from '../../../../reusable_components/forms/TextAreaInput'
import CheckboxInput from '../../../../reusable_components/forms/CheckboxInput'
import FollowButton from '../../../../reusable_components/buttons/FollowButton'
import { TicketCourseWrapper, TicketImageWrapper } from '../index/TicketsPage'
import ResponsiveImage from '../../../../reusable_components/responsive/ResponsiveImage'

const UpdateBooking = gql`
	mutation ($input: UpdateBookingInput!) {
		updateBooking(input: $input) {
			booking {
				id
			}
			errors {
				path
				message
			}
		}
	}
`
const Cancel = ({ t, ticket, userIsParticipating, isPublished }) => {
	const { courseDate, booking, transferPath } = ticket
	const { course, refundCutOff, refundCutOffDate, serviceFee } = booking
	const { payOnSite } = courseDate
	const { host, defaultImagePath, title, purchasePolicyUrl } = course
	const { hostPage } = host
	const routes = useRoutes()
	const history = useHistory()
	const [modalState, setModalState] = useState(null)
	const [chosenReason, setChosenReason] = useState(null)
	const [isRefunded, setIsRefunded] = useState(!!booking.refundedAt)
	const [updateBookingResult, updateBooking] = useMutation(UpdateBooking)

	const onCancel = (input, onSubmitFinished) => {
		const variables = {
			input: { id: booking.id, makeRefund: true },
		}

		updateBooking(variables).then(response => {
			const unsuccessful = response.error || response?.data?.updateBooking.errors.length > 0
			if (unsuccessful) {
				onSubmitFinished(response.error || response?.data?.updateBooking)
			} else {
				setIsRefunded(true)
				setModalState('WHAT_IS_THE_REASON')
			}
		})
	}
	const onReasonGiven = (input, onSubmitFinished) => {
		const variables = {
			input: {
				id: booking.id,
				refundedComment:
					chosenReason === 'OTHER' ? input.refundedComment : t(`admin:cancelOrReschedulePage.${chosenReason}`),
			},
		}

		updateBooking(variables).then(response => {
			const unsuccessful = response.error || response?.data?.updateBooking.errors.length > 0
			if (unsuccessful) {
				onSubmitFinished(response.error || response?.data?.updateBooking)
			} else {
				setModalState('FAVOURITE_WORKSHOP')
			}
		})
	}
	const refundReasons = ['SICK', 'BUSY', 'OTHER']

	useEffect(() => {
		// We can not let them find a new date, transfer their spot (or request a refund again) after they have canceled their booking, so reroute them to TicketsPage after a refund has been made and they close the modal
		if (isRefunded && !modalState) history.push(routes.admin.account.tickets.path)
	}, [isRefunded, modalState])

	return (
		<>
			{booking.canBeCancelled ? (
				<p>
					{payOnSite
						? t('admin:cancelOrReschedulePage.cancelTextPayOnSite', { date: refundCutOffDate })
						: t('admin:cancelOrReschedulePage.cancelText', { date: refundCutOffDate, serviceFee })}
				</p>
			) : (
				<>
					<P4 textColor="errorColor">
						<Trans i18nKey={'admin:cancelOrReschedulePage.notPossibleToRefund'} values={{ days: refundCutOff }}>
							You can no longer cancel your booking because there are less than 10 days left until the start of the
							course. Read more in our
							<a href={purchasePolicyUrl} target="_blank" rel="noopener">
								purchase policy
							</a>
						</Trans>
					</P4>
					{userIsParticipating && isPublished && (
						<>
							<br />
							<p>
								<Trans i18nKey={'admin:cancelOrReschedulePage.notPossibleToRefundTip'}>
									I accept Ejbla's Tip! You can
									<Link to={transferPath}>
										transfer your place to another participant until the start of the course.
									</Link>
								</Trans>
							</p>
						</>
					)}
				</>
			)}
			<SmallPageSection>
				<Button disable={!booking.canBeCancelled} onClick={() => setModalState('ARE_YOU_SURE')}>
					{t('common:buttons.cancelBooking')}
				</Button>
			</SmallPageSection>
			<DynamicModal
				displayModal={!!modalState}
				setDisplayModal={() => {
					setModalState(null)
				}}
			>
				{modalState === 'ARE_YOU_SURE' && (
					<>
						<P3>{t('admin:cancelOrReschedulePage.areYouSure')}</P3>
						<SmallPageSection>
							<p>
								{booking.totalPrice
									? t('admin:cancelOrReschedulePage.areYouSureText', { name: host.fullName, serviceFee })
									: t('admin:cancelOrReschedulePage.areYouSureTextNoFee', { name: host.fullName })}
							</p>
						</SmallPageSection>
						<Form onSubmit={onCancel}>
							<CenteredSection>
								<Button>{t('common:buttons.refundBooking')}</Button>
							</CenteredSection>
						</Form>
					</>
				)}
				{modalState === 'WHAT_IS_THE_REASON' && (
					<>
						<P3>{t('admin:cancelOrReschedulePage.isNowCancelled')}</P3>
						<SmallPageSectionDivider />
						<SmallPageSection>
							<P3>{t('admin:cancelOrReschedulePage.reason')}</P3>
						</SmallPageSection>
						{refundReasons.map((refundReason, key) => (
							<CheckboxInput
								key={key}
								name={refundReason}
								onChange={() => setChosenReason(refundReason)}
								checked={chosenReason === refundReason}
								label={t(`admin:cancelOrReschedulePage.${refundReason}`)}
							/>
						))}
						<Form onSubmit={onReasonGiven} forceDisable={!chosenReason}>
							{chosenReason === 'OTHER' && <TextAreaInput name="refundedComment" required />}
							<CenteredSection>
								<Button>{t('common:buttons.send')}</Button>
							</CenteredSection>
						</Form>
					</>
				)}
				{modalState === 'FAVOURITE_WORKSHOP' && (
					<>
						<P3>{t('admin:cancelOrReschedulePage.followTitle')}</P3>
						<SmallPageSection>
							<p>{t('admin:cancelOrReschedulePage.followText')}</p>
						</SmallPageSection>
						<TicketCourseWrapper>
							<TicketImageWrapper>
								<ResponsiveImage type="SQUARE" imagePath={defaultImagePath} />
							</TicketImageWrapper>
							<div>
								<P2 useAsTitle>{title}</P2>
								<p>
									{t('admin:ticketsPage.hostedBy')}{' '}
									{hostPage?.path ? <Link to={hostPage.path}>{host.fullName}</Link> : host.fullName}
								</p>
								<SmallPageSection>
									<FollowButton
										favouritableType="course"
										favouritableId={course.id}
										followText={t('common:buttons.notifyMe')}
										followingText={t('common:buttons.notifyingMe')}
									/>
								</SmallPageSection>
							</div>
						</TicketCourseWrapper>
					</>
				)}
			</DynamicModal>
		</>
	)
}

export default Cancel
