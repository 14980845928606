import React, { useEffect } from 'react'
import {
	initializeSentry,
	isServerRendering,
	isSessionStorageAvailable,
	setAdminDestination,
	setApiKeyStore,
	setManifestLoaded,
} from './utils/applicationHelper'
import ScreenSizeProvider from './contexts/screen_size/ScreenSizeProvider'
import { cacheExchange, createClient, fetchExchange, Provider } from 'urql'
import initializeI18n from './components/reusable_components/middleware/NewExportedI18nextProvider'
import UserProvider from './contexts/user/UserProvider'
import GroupInquiryProvider from './contexts/group_inquiry/GroupInquiryProvider'
import { setRoutes } from './hooks/useRoutes'
import { setLocation } from './hooks/useLocation'
import getCsrfToken, { setCsrfToken } from './hooks/getCsrfToken'
import { setWhiteLabel } from './hooks/getWhiteLabel'
// import { setHelpScoutAppIds } from './utils/helpScoutHelper'

//install Graphcache?

initializeSentry()

const App = props => {
	const {
		locale,
		user,
		routes,
		children,
		railsContext,
		whiteLabelIcon,
		helpScoutAppIds,
		googleMapsSdkApiKey,
		adminDestination,
		pwaSsr,
		stripePublishableKey,
		manifestLoaded,
	} = props
	setRoutes(routes)
	setLocation(railsContext)
	setWhiteLabel({ active: !!whiteLabelIcon, icon: whiteLabelIcon })
	setCsrfToken()
	setManifestLoaded(manifestLoaded)
	// setHelpScoutAppIds(helpScoutAppIds)
	setApiKeyStore({ GOOGLE_MAPS_SDK: googleMapsSdkApiKey, STRIPE_PUBLISHABLE_KEY: stripePublishableKey })
	const csrfToken = getCsrfToken()
	initializeI18n(locale)
	// Our Cookie consent right now isn't GDPR compatible, so there is not point in showing it. Also, Chrome will soon not allow third party cookies anyways, and iPhone will stop showing "distracting" elements such as cookie popups
	// initializeCookieConsent(locale)
	const client = createClient({
		url: `${railsContext.origin}/${locale}/graphql`,
		fetchOptions: () => {
			return {
				headers: { 'X-CSRF-Token': csrfToken },
			}
		},
		exchanges: [cacheExchange, fetchExchange],
	})
	if (!isServerRendering() && !window.filterCache) {
		window.filterCache = {} // Initialize the filter cache
	}
	useEffect(() => {
		if (isSessionStorageAvailable() && pwaSsr) sessionStorage.setItem('pwaInstalled', 'TRUE')

		// Clean up any old modal params
		const url = new URL(location.href)
		if (url.searchParams.get('modal')) {
			url.searchParams.delete('modal')
		}
		window.history.replaceState({}, document.title, url.href)
	}, [])
	setAdminDestination(adminDestination)
	return (
		<Provider value={client}>
			<ScreenSizeProvider railsContext={railsContext}>
				<UserProvider
					user={user}
					adminDestination={adminDestination}
					pwaSsr={pwaSsr}
					rootPath={routes.course.startPage.path}
				>
					<GroupInquiryProvider>{children}</GroupInquiryProvider>
				</UserProvider>
			</ScreenSizeProvider>
		</Provider>
	)
}

export default App
