import React from 'react'
import { Link } from 'react-router-dom'
import { TextSpan } from '../Typography'

// Useful when needing to render a link when there is url, and not a link otherwise, since Link requires a "to" prop
const ConditionalLink = ({ to, children, disabledColor, ...rest }) =>
	to ? (
		to.includes('https://') ? (
			<a href={to} {...rest}>
				{children}
			</a>
		) : (
			<Link to={to} {...rest}>
				{children}
			</Link>
		)
	) : (
		<TextSpan textColor={disabledColor || 'lightTextColor'} {...rest}>
			{children}
		</TextSpan>
	)

export default ConditionalLink
