import React from 'react'
import SvgShell from './SvgShell'

const Share = props => (
	<SvgShell {...props}>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
			<rect width="256" height="256" fill="none" />
			<polyline
				points="176 152 224 104 176 56"
				fill="none"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="16"
			/>
			<path
				d="M192,216H40a8,8,0,0,1-8-8V88"
				fill="none"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="16"
			/>
			<path
				d="M75.0245,175.99967A96.04041,96.04041,0,0,1,168,104h56"
				fill="none"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="16"
			/>
		</svg>
	</SvgShell>
)

export default Share
