export default (elementIdToScrollTo, flashElement = false, callback) => {
	const targetElement = document.getElementById(elementIdToScrollTo)
	if (targetElement) {
		targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
		if (callback) {
			callback()
		}
		if (flashElement) {
			flash(targetElement)
		}
	}
}

// Highlight element by flashing it
function flash(targetElement) {
	targetElement.style.transition = 'opacity 0.5s'
	targetElement.style.opacity = '1'
	targetElement.style.opacity = '0.2'
	setTimeout(unFlash, 500, targetElement)
}
function unFlash(targetElement) {
	targetElement.style.opacity = '1'
	setTimeout(resetElement, 500, targetElement)
}
function resetElement(targetElement) {
	targetElement.style.transition = ''
	targetElement.style.opacity = ''
}
