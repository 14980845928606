import React from 'react'
import Share from '../svg_icons/Share'
import { isServerRendering } from '../../../utils/applicationHelper'

const ShareButton = ({ title, text }) => {
	const onClick = async () => {
		try {
			// Open the mobile's native share tool
			await navigator.share({ title, text, url: location.href })
		} catch (err) {
			console.log('Error: ', err)
		}
	}
	// Only display the share button if the client supports native phone sharing (navigator.share)
	return !isServerRendering() && navigator.share ? (
		<Share width={22} height={22} onClick={onClick} id="GTM_SHARE_BUTTON" />
	) : null
}

export default ShareButton
