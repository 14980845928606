import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../../reusable_components/buttons/Button'
import CourseAndItsDates from './CourseAndItsDates'
import {
	CenteredPageSection,
	CenteredSection,
	DynamicPageSection,
	SmallPageSectionDivider,
} from '../../reusable_components/layout/PageComponents'
import { theme } from '../../../utils/theme'
import LoadingSpinner from '../../reusable_components/other/LoadingSpinner'

const getCoursesWithDates = newCourseCards => {
	const coursesWithDates = []
	for (let course of newCourseCards) {
		if (course.bookableDates?.length > 0) coursesWithDates.push(course)
	}
	return coursesWithDates
}

const DatesSection = ({ hostId, hostsBookingLinkWasUsed = false, initialCoursesWithDates = [], t, i18n }) => {
	const [courses, setCourses] = useState(getCoursesWithDates(initialCoursesWithDates))
	const [numberOfShownCourses, setNumberOfShownCourses] = useState(5)
	const [loading, setLoading] = useState(initialCoursesWithDates.length === 0)
	const getCourses = () => {
		if (loading) {
			const url = new URL(location.href)
			axios({
				method: 'get',
				url: '/api/v1/public/course_cards',
				params: {
					quantity: 100,
					offset: 0,
					id: hostId,
					hosts_booking_link_was_used: hostsBookingLinkWasUsed,
					course_marketplace_param: url.searchParams.get('cm'),
					type: 'experts',
					locale: i18n.language,
				},
			}).then(response => {
				const newCourseCards = response.data.courseCards
				setCourses([...getCoursesWithDates(newCourseCards)])
				setLoading(false)
			})
		}
	}
	useEffect(() => {
		getCourses()
	}, [])
	if (loading)
		return (
			<CenteredPageSection>
				<LoadingSpinner />
			</CenteredPageSection>
		)
	if (courses.length === 0) return null
	return (
		<div>
			<SmallPageSectionDivider />
			<h2>{t('copy:hostPage.hostsUpcomingDates')}</h2>
			{courses?.slice(0, numberOfShownCourses).map((course, key) => (
				<CourseAndItsDates key={key} t={t} course={course} />
			))}
			{numberOfShownCourses < courses.length && (
				<DynamicPageSection margins={{ bottom: theme.margins.largeInt }}>
					<CenteredSection>
						<Button
							color="OUTLINED_BLACK"
							submitted={loading}
							onClick={() => setNumberOfShownCourses(numberOfShownCourses + 5)}
						>
							{t('common:buttons.seeMoreWorkshops')}
						</Button>
					</CenteredSection>
				</DynamicPageSection>
			)}
		</div>
	)
}

export default DatesSection
