import React, { useEffect, useState } from 'react'
import CrocodileRight from '../../reusable_components/svg_icons/CrocodileRight'
import { LeftAlignedFlexBox } from '../../reusable_components/layout/PageComponents'
import { theme } from '../../../utils/theme'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import { useHistory, useLocation as reactRouterUseLocation } from 'react-router-dom'

const BackAndForwardButtons = () => {
	const history = useHistory()
	const [loaded, setLoaded] = useState(false)
	// useLocation is needed here to refresh navigation.canGoBack and canGoForward, even though we don't use its return value
	const location = reactRouterUseLocation()
	useEffect(() => {
		// useEffect so SSR and client render is the same
		setLoaded(true)
	}, [])
	return (
		<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
			<LeftAlignedFlexBox>
				<CrocodileRight
					style={{ transform: 'rotateZ(180deg)' }}
					fill={theme.colors[loaded && navigation?.canGoBack ? 'black' : 'gray']}
					onClick={history.goBack}
				/>
				<CrocodileRight
					fill={theme.colors[loaded && navigation?.canGoForward ? 'black' : 'gray']}
					onClick={history.goForward}
				/>
			</LeftAlignedFlexBox>
		</Breakpoint>
	)
}

export default BackAndForwardButtons
